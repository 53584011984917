import React, { useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './carousel.css';

const CarouselWithCards: React.FC = () => {
  // Ref a carousel-inner elemhez
  const carouselInnerRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const cardWidth = 300; // Az igazításhoz szükséges kártya szélessége
  const visibleCards = 4; // Látó kártyák száma a carousel-ben

  // Következő kártya előre mozgatása
  const handleNextClick = () => {
    if (carouselInnerRef.current) {
      const carouselWidth = carouselInnerRef.current.scrollWidth;
      const maxScrollPosition = carouselWidth - (cardWidth * visibleCards);

      if (scrollPosition < maxScrollPosition) {
        setScrollPosition(prevPosition => {
          const newPosition = Math.min(prevPosition + cardWidth, maxScrollPosition);
          carouselInnerRef.current!.scrollTo({ left: newPosition, behavior: 'smooth' });
          return newPosition;
        });
      }
    }
  };

  // Előző kártya hátra mozgatása
  const handlePrevClick = () => {
    if (carouselInnerRef.current) {
      if (scrollPosition > 0) {
        setScrollPosition(prevPosition => {
          const newPosition = Math.max(prevPosition - cardWidth, 0);
          carouselInnerRef.current!.scrollTo({ left: newPosition, behavior: 'smooth' });
          return newPosition;
        });
      }
    }
  };

  

  // Szűrő kategória váltás
  const handleFilterChange = (category: string) => {
    setSelectedCategory(category);
    setScrollPosition(0); // Kategória váltáskor állítsuk vissza a görgetési pozíciót
    // Frissítjük a filter-active osztályokat
    const filters = document.querySelectorAll('.filter');
    filters.forEach(filter => filter.classList.remove('filter-active'));
    const activeFilter = document.querySelector(`.filter[data-filter="${category}"]`);
    if (activeFilter) {
      activeFilter.classList.add('filter-active');
    }
  };


  

  return (
    <>
      <div className="menu-tab-wp" id='menu'>
        <div className="row">
          <div className="col-lg-12 m-auto">
            <div className="menu-tab text-center">
              <ul className="filters-menu">
                <li className={`filter ${selectedCategory === 'all' ? 'filter-active' : ''}`} data-filter="all" onClick={() => handleFilterChange('all')}  >
                  <img src="all-you-can-eat.png" alt="" />
                  <p data-languagePartIdentifier="osszes">Összes</p>
                </li>
                <li className={`filter ${selectedCategory === 'pizza' ? 'filter-active' : ''}`} data-filter="pizza" onClick={() => handleFilterChange('pizza')} >
                  <img src="pizza.png" alt="" />
                  <p data-languagePartIdentifier="pizza">Pizzák</p>
                </li>
                <li  className={`filter ${selectedCategory === 'gyros' ? 'filter-active' : ''}`} data-filter="gyros" onClick={() => handleFilterChange('gyros')} >
                  <img src="kebab.png" alt="" />
                 <p data-languagePartIdentifier="gyros">Gyros</p> 
                </li>
                <li  className={`filter ${selectedCategory === 'hot-dog' ? 'filter-active' : ''}`} data-filter="hot-dog" onClick={() => handleFilterChange('hot-dog')} >
                <img src="hot-dog.png" alt="" />
                 <p data-languagePartIdentifier="hotdog">Hot-dog</p> 
                </li>
                <li className={`filter ${selectedCategory === 'kosar' ? 'filter-active' : ''}`} data-filter="kosar" onClick={() => handleFilterChange('kosar')} >
                  <img src="fried-chicken.png" alt="" />
                 <p data-languagePartIdentifier="kosar">Kosarak</p>
                </li>
                <li className={`filter ${selectedCategory === 'friss' ? 'filter-active' : ''}`} data-filter="friss" onClick={() => handleFilterChange('friss')} >
                  <img src="fresh.png" alt="" />
                  <p data-languagePartIdentifier="friss">Frissensültek</p>
                 
                </li>
                <li className={`filter ${selectedCategory === 'hambi' ? 'filter-active' : ''}`} data-filter="hambi" onClick={() => handleFilterChange('hambi')} >
                  <img src="hamburger.png" alt="" />
                  <p data-languagePartIdentifier="hambi">Hamburgerek</p>
                </li>
                <li className={`filter ${selectedCategory === 'fal' ? 'filter-active' : ''}`} data-filter="fal" onClick={() => handleFilterChange('fal')} >
                  <img src="food.png" alt="" />
                  <p data-languagePartIdentifier="fal">Falafel</p>
                </li>
                <li className={`filter ${selectedCategory === 'franc' ? 'filter-active' : ''}`} data-filter="franc" onClick={() => handleFilterChange('franc')} >
                  <img src="french-toast.png" alt="" />
                  <p data-languagePartIdentifier="francia"> Franciapirítós</p>
                </li>
                <li className={`filter ${selectedCategory === 'egyeb' ? 'filter-active' : ''}`} data-filter="egyeb" onClick={() => handleFilterChange('egyeb')} >
                  <img src="sweets.png" alt="" />
                  <p data-languagePartIdentifier="egyeb">Egyebek</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="carousel slide" style={{ backgroundColor: "#0c0c0cfd" }}>
        <div className="carousel-inner" ref={carouselInnerRef}>
          {/* Pizzák */}
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="margeritha">Margherita</h5>
                <p className="card-text" data-languagePartIdentifier="paradicsom" >paradicsomos alap,paradicsomkarikák,mozzarella</p>
                <p  className="card-text">2.350 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="sonka-kuk">Sonka - kukoricás</h5>
                <p className="card-text" data-languagePartIdentifier="sonk-par">paradicsomos alap,sonka,kukorica,mozzarella</p>
                <p  className="card-text">2.750 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="Hawaii">Hawaii</h5>
                <p className="card-text" data-languagePartIdentifier="par-an">paradicsomos alap,sonka,ananász,mozzarella</p>
                <p  className="card-text">2.800 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="son-go-ku">Son-go-ku</h5>
                <p className="card-text" data-languagePartIdentifier="par-gomb">paradicsomos alap,sonka,gomba,kukorica,mozzarella</p>
                <p  className="card-text">2.850 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="sierra">Sierra</h5>
                <p className="card-text" data-languagePartIdentifier="par-sir">paradicsomos alap,sonka,gomba,lilahagyma,paradicsomkarikák,mozzarella</p>
                <p  className="card-text">2.850 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="vega">Vegetáriánus</h5>
                <p className="card-text" data-languagePartIdentifier="veg-par">paradicsomos alap,gomba,kukorica,paradicsomkarikák,paprika,lilahagyma,mozzarella</p>
                <p  className="card-text">2.850 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="4sajt">4sajtos</h5>
                <p className="card-text" data-languagePartIdentifier="sajt-par" >paradicsomos alap,parmezán,cheddar,fetasajt,mozzarella</p>
                <p  className="card-text">2.950 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="pro">Prosciutto</h5>
                <p className="card-text" data-languagePartIdentifier="par-pro">paradicsomos alap,Prosciutto sonka,mozzarella</p>
                <p  className="card-text">2.950 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="magy">Magyaros</h5>
                <p className="card-text" data-languagePartIdentifier="magy-par">paradicsomos alap,kolbász,bacon,paradicsom,lilahagyma,paprika,szalámi.</p>
                <p  className="card-text">3.100 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="parasztos">Parasztos</h5>
                <p className="card-text" data-languagePartIdentifier="par-par">paradicsomos alap,kolbász,bacon,tükörtojás,paradicsom,lilahagyma,mozzarella</p>
                <p className="card-text">3.150 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="piedone">Piedone</h5>
                <p className="card-text" data-languagePartIdentifier="par-piedone">paradicsomos alap,kolbász,bacon,bab,lilahagyma,mozzarella</p>
                <p className="card-text">3.150 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="amore-mio">Amore mio</h5>
                <p className="card-text" data-languagePartIdentifier="amore-par">paradicsomos alap, sonka,gyroshús,lilahagyma,paradicsom</p>
                <p className="card-text">3.150 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="dolce-vita">Dolce Vita</h5>
                <p className="card-text" data-languagePartIdentifier="dolce-par">paradicsomos alap,sonka,bacon,jalapeno,lilahagyma</p>
                <p className="card-text">3.150 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="raffaelo">Raffaelo</h5>
                <p className="card-text" data-languagePartIdentifier="raffelo-par">paradicsomos alap,sonka,gyroshús,gomba,kukorica,lilahagyma,mozzarella</p>
                <p className="card-text">3.250 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="betyaros">Betyáros</h5>
                <p className="card-text" data-languagePartIdentifier="bety-par">paradicsomos alap,bacon,tarja,jalapeno,kukorica,lilahagyma,mozzarella</p>
                <p className="card-text">3.250 Ft</p> 
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="csuloks">Csülkös</h5>
                <p className="card-text" data-languagePartIdentifier="csul-par">paradicsomos alap,füstölt csülök,jalapeno,lilahagyma,mozzarella</p>
                <p className="card-text">3.250 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="gyrosos">Gyrosos</h5>
                <p className="card-text" data-languagePartIdentifier="gyros-par">paradicsomos alap,gyroshús,paradicsom,uborka,lilahagyma,fetasajtmozzarella</p>
                <p className="card-text">3.350 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="toldi">Toldi</h5>
                <p className="card-text" data-languagePartIdentifier="toldi-par">paradicsomos alap,sonka,kolbász,bacon,fetasajt,mozzarella</p>
                <p className="card-text">3.350 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="joker-pi">Joker</h5>
                <p className="card-text" data-languagePartIdentifier="joker-par">paradicsomos alap,sonka,bacon,szalámi,tarja,lilahagyma,mozzarella</p>
                <p className="card-text">3.550 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="kivansag-1">Kívánság pizza 1</h5>
                <p className="card-text" data-languagePartIdentifier="kiv-fel">3 hús + 2 zöldség feltéttel</p>
                <p className="card-text">3.550 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="kiv-2">Kívánság pizza 2</h5>
                <p className="card-text" data-languagePartIdentifier="kis-fel-2">5 feltét felett</p>
                <p className="card-text">4.350 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="gusto">Gusto</h5>
                <p className="card-text" data-languagePartIdentifier="tel-gusto">tejfölös alap,szalámim,kukorica,mozzarella</p>
                <p className="card-text">2.800 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="avanti">Avanti</h5>
                <p className="card-text" data-languagePartIdentifier="tel-avanti">tejfölös alap,gyroshús,kolbász,gomba,mozzarella</p>
                <p className="card-text">3.150 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="biotticelli">Botticelli</h5>
                <p className="card-text" data-languagePartIdentifier="tel-boti">tejfölös alap,sonka,gyroshús,gomba,kukorica,mozzarella</p>
                <p className="card-text">3.250 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="gyrosos-tel">Gyrosos</h5>
                <p className="card-text" data-languagePartIdentifier="gyr-tel">tejfölös alap,gyroshús,paradicsom,uborka,lilahagyma,fetasajt,mozzarella</p>
                <p className="card-text">3.350 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="csir">Csirkés</h5>
                <p className="card-text" data-languagePartIdentifier="csir-tel">paradicsomos alap,gyroshús,paradicsom,paprika,lilahagyma,mozzarella</p>
                <p className="card-text">3.350 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="unger">Ungherese</h5>
                <p className="card-text" data-languagePartIdentifier="tel-ung">paradicsomos alap,szalámi,bacon,paradicsom,lilahagyma,paprika,mozzarella</p>
                <p className="card-text">3.450 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="tolt-gyros">Gyrosos</h5>
                <p className="card-text" data-languagePartIdentifier="tel-tolt-gyros">tejfölös alap,gyroshús,paradicsom,paprika,lilahagyma,fetasajt,káposzta,mozzarella</p>
                <p className="card-text">3.550 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'pizza' ? 'd-block' : 'd-none'} pizza`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="feltetek">Feltétek</h5>
                <p className="card-text" data-languagePartIdentifier="tojas">+ tojás - 300 Ft</p>
                <p className="card-text" data-languagePartIdentifier="zoldseg">+ zöldségek - 400 Ft</p>
                <p className="card-text" data-languagePartIdentifier="sajt">+ sajt - 500 Ft</p>
                <p className="card-text" data-languagePartIdentifier="hus">+ hús - 650 Ft</p>
              </div>
            </div>
          </div>
          
          
    {/* Gyros */}


          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="gyros-pit">Gyros pita</h5>
                <p className="card-text" data-languagePartIdentifier="gyros-tolt">Gyroshús,paradicsom,uborka,lilahagyma,káposzta,tartármártás</p>
                <p className="card-text" >2.150 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="sajt-gyros">Sajtos Gyros pita</h5>
                <p className="card-text" data-languagePartIdentifier="sajt-gyros-tolt">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <p className="card-text">2.350 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="gyors-sima">Gyros</h5>
                <p className="card-text" data-languagePartIdentifier="gyros-sim-tolt">Gyroshús,paradicsom,uborka,lilahagyma,káposzta,tartármártás,sajt</p>
                <p className="card-text">2.750 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="gorog-gyros">Görög gyros pita (20cm) nagy méret</h5>
                <p className="card-text" data-languagePartIdentifier="gorog-gyros-tolt">Gyroshús,paradicsom,uborka,lilahagyma,jégsaláta,olivabogyó,fetasajt,tzatziki öntet</p>
                <p className="card-text">2.750 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="gyros-tortilla">Gyros tortilla</h5>
                <p className="card-text" data-languagePartIdentifier="gyros-tort-tolt">Gyroshús,paradicsom,uborka,lilahagyma,káposzta,tartármártás</p>
                <p className="card-text">2.350 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="joker-tort">Joker tortilla</h5>
                <p className="card-text" data-languagePartIdentifier="joker-tolt">Gyroshús,bacon,paradicsom,uborka,lilahagyma,káposzta,pirított hagyma,tartármártás,sajt</p>
                <p className="card-text">2.650 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="gyros-tal">Gyrostál</h5>
                <p className="card-text" data-languagePartIdentifier="gyrostal-felt">Gyroshús,paradicsom,uborka,lilahagyma,káposzta,tartármártás,hasábburgonya</p>
                <p className="card-text">3.150 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="gorogtal">Görög gyrostál</h5>
                <p className="card-text" data-languagePartIdentifier="gorogtal-felt">Gyroshús,paradicsom,uborka,lilahagyma,jégsaláta,olivabogyó,fetasajt,tzatziki öntet, görög pita, hasábburgonya</p>
                <p className="card-text">3.550 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="gyros-edes">Gyrostál édesburgonyával</h5>
                {/* <p className="card-text" data-languagePartIdentifier="gyros-tolt">Gyroshús,paradicsom,uborka,lilahagyma,hasábburgonya,tartármártás</p> */}
                <p className="card-text">3.600 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="sajt-gyrostal">Sajtos gyrostál</h5>
                <p className="card-text" data-languagePartIdentifier="sajt-gyrostal-felt">gyroshús,paradicsom,uborka,lilahagyma,káposzta,hasábburgonya,tartásrmártás,sajt</p>
                <p className="card-text">3.350 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="sajtos-edes">Sajtos gyrostál édesburgonyával</h5>
                <p className="card-text"></p>
                <p className="card-text">3.800 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="mammut">Mammut-gyrostál</h5>
                <p className="card-text" data-languagePartIdentifier="mammut-felt">dupla gyroshús,paradicsom,uborka,lilahagyma,káposzta,sajt,hasábburgonya,tartármártás</p>
                <p className="card-text">4.150 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="mammut-edes">Mammut-gyrostál édesburgonyával</h5>
                <p className="card-text"></p>
                <p className="card-text">4.500 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="joker-tal">Joker gyrostál</h5>
                <p className="card-text" data-languagePartIdentifier="joker-tal-felt">dupla gyroshús,paradicsom,uborka,lilahagyma,jégsaláta,olivabogyó,fetasajt,tzatziki öntet, görög pita, hasábburgonya</p>
                <p className="card-text">4.350 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'gyros' ? 'd-block' : 'd-none'} gyros`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="joker-edes-tal">Joker gyrostál édesburgonyával</h5>
                <p className="card-text">4.700 Ft</p>
                
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
      

{/*Hot-dog*/}


          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'hot-dog' ? 'd-block' : 'd-none'} hot-dog`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="hot-dog-kif">Hot-dog</h5>
                <p className="card-text" data-languagePartIdentifier="hot-dog-felt">Kifli,virsli,ketchup,mustár,majonéz</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'hot-dog' ? 'd-block' : 'd-none'} hot-dog`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="sajtos-dog">Sajtos hot-dog</h5>
                <p className="card-text" data-languagePartIdentifier="sajt-hot-felt">Kifli, virsli,ketchup,mustár,majonéz</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'hot-dog' ? 'd-block' : 'd-none'} hot-dog`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="zold-dog">Zöldséges hot-dog</h5>
                <p className="card-text" data-languagePartIdentifier="zold-dog-felt">Kifli,virsli,paradicsom,uborka,lilahagyma,ketchup,mustár,majonéz</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'hot-dog' ? 'd-block' : 'd-none'} hot-dog`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="sajtos-zold">Sajtos zöldséges hot-dog</h5>
                {/* <p className="card-text" data-languagePartIdentifier="gyros-tolt">Kifli,virsli,sajt,paradicsom,uborka,lilahagyma,ketchup,mustár,majonéz</p> */}
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>


{/*Boxok*/}


          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'box' ? 'd-block' : 'd-none'} box`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="gyros-box">Gyros box</h5>
                <p className="card-text" data-languagePartIdentifier="gyros-box-felt">gyroshús,paradicsom,uborka,lilahagyma,káposzta,hasábburgonya,választható öntet,sajt</p>
                <p className="card-text">2.650 Ft</p>
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'box' ? 'd-block' : 'd-none'} box`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="gorog-box">Görög gyros box</h5>
                <p className="card-text" data-languagePartIdentifier="gorog-box-felt">gyroshús,paradicsom,uborka,lilahagyma,olivabogyó,fetasajt,hasábburgonya,tzatziki öntet,sajt</p>
               <p className="card-text">2.750 Ft</p>
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'box' ? 'd-block' : 'd-none'} box`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="fit-box">Fitt gyros box</h5>
                <p className="card-text" data-languagePartIdentifier="fit-box-felt">gyroshús,jégsaláta,paradicsom,uborka,lilahagyma,választható öntet,sajt</p>
                <p className="card-text">2.750 Ft</p>
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'box' ? 'd-block' : 'd-none'} box`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="falafel-box">Falafel box</h5>
                <p className="card-text" data-languagePartIdentifier="falafel-box-feltet">5db falafel, hasábbirgonya,választható mártogatós</p>
                <p className="card-text">2.550 Ft</p>
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'box' ? 'd-block' : 'd-none'} box`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="chicken-nug">Chicken nuggets box</h5>
                <p className="card-text" data-languagePartIdentifier="chicken-felt">6db chicken nuggets,hasábburgonya,választhjató mártogatós</p>
               <p className="card-text">2.550 Ft</p>
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'box' ? 'd-block' : 'd-none'} box`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="camember-box">Camember box</h5>
                <p className="card-text" data-languagePartIdentifier="camember-felt">6db rántott camembert,hasábburgonya,választható mártogatós</p>
               <p className="card-text">2.550 Ft</p>
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'box' ? 'd-block' : 'd-none'} box`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="chedar-box">Chedar sajt jalapeno box</h5>
                <p className="card-text" data-languagePartIdentifier="chedar-felt">4db cheddar sajttal tölött jalapeno,hasábburgonya,választható mártogatós</p>
              <p className="card-text">2.550 Ft</p>
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'box' ? 'd-block' : 'd-none'} box`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="krem-box">Krémsajttal töltött jalapeno box</h5>
                <p className="card-text" data-languagePartIdentifier="krem-felt">4db krémsajttal tölött jalapeno,hasábburgonya,választható mártogatós</p>
               <p className="card-text">2.550 Ft</p>
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'box' ? 'd-block' : 'd-none'} box`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="vega-box">Vega box</h5>
                <p className="card-text" data-languagePartIdentifier="vega-felt">2db rántott sajat,hasábburgonya,választható mártogatós</p>
                <p className="card-text ">2.550Ft</p>
              <p className="card-text">2.550 Ft</p>
              </div>
            </div>
          </div>


          {/*Kosarak*/}


          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'kosar' ? 'd-block' : 'd-none'} kosar`} style={{width: "0px !important"}}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="nuggets-kos">Nuggets egyszemélyes kosár</h5>
                <p className="card-text" data-languagePartIdentifier="nuggets-tart">4db csrikenuggets,4db csípős csirkenuggets,6db rántott hagymakarika,hasábburgonya,választható mártogatós</p>
                <p className="card-text ">2.850 Ft</p>
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'kosar' ? 'd-block' : 'd-none'} kosar`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="pikantne">Spicy kosár</h5>
                <p className="card-text" data-languagePartIdentifier="pik-tar">4db cheddar sajt jalapeno,8db csípős csirkenuggets,hasábburgonya,választható mártogatós</p>
                <p className="card-text ">3.250 Ft</p>
              </div>
            </div>
          </div>


         {/*Frissensültek*/}

          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'friss' ? 'd-block' : 'd-none'} friss`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="csir-6">Chicken nuggets 6db</h5>
                <p className="card-text ">1.750 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'friss' ? 'd-block' : 'd-none'} friss`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="csir-9">Chicken nuggets 9db</h5>
                <p className="card-text ">1.950 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'friss' ? 'd-block' : 'd-none'} friss`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="pik-6">Csípős nuggets 6db</h5>
                <p className="card-text ">1.850 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'friss' ? 'd-block' : 'd-none'} friss`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="pik-9">Csípős nuggets 9db</h5>
                <p className="card-text ">2.050 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'friss' ? 'd-block' : 'd-none'} friss`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="smaz">Rántott csirkemellcsíkok ropogós</h5>
                <p className="card-text ">2.650 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'friss' ? 'd-block' : 'd-none'} friss`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="smaz-cam">Rántott camembert áfonyalekvárral hasábburgonyával</h5>
                <p className="card-text ">3.150 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'friss' ? 'd-block' : 'd-none'} friss`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="smaz-ser">Rántott sajt hasábburgonyával tartármártással</h5>
                <p className="card-text ">2.650 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
         

  {/*Hamburger*/}

  <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'hambi' ? 'd-block' : 'd-none'} hambi`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="hamb">Hamburger</h5>
                <p className="card-text" data-languagePartIdentifier="hamb-tar">marhahúspogácsa,jégsaláta,paradicsom,uborka,lilahagyma,hambiszósz/ketchup/mustár/majonéz</p>
                <p className="card-text ">2.550 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'hambi' ? 'd-block' : 'd-none'} hambi`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="sajt-hamb">Sajtos hamburger</h5>
                <p className="card-text" data-languagePartIdentifier="sajt-tar">marhahúspogácsa,cheddar sajt,jégsaláta,paradicsom,uborka,lilahagyma,hambiszósz/ketchup/mustár/majonéz</p>
                <p className="card-text ">2.750 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'hambi' ? 'd-block' : 'd-none'} hambi`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="vega-hamb">Vega burger</h5>
                <p className="card-text" data-languagePartIdentifier="vega-tar">rántott sajt,jégsaláta,paradicsom,uborka,rántott hagymakarika,hambiszózs/ketchup/mustár/majonéz</p>
                <p className="card-text ">2.750 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'hambi' ? 'd-block' : 'd-none'} hambi`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="fit-burger">Fitness burger</h5>
                <p className="card-text" data-languagePartIdentifier="fit-tar">gyroshús,cheddar sajt,jégsaláta,paradicsom,uborka,lilahagyma,hambiszósz/ketchup6mustár/majonéz</p>
                <p className="card-text ">2.750 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'hambi' ? 'd-block' : 'd-none'} hambi`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="ogien-burg">Pokol tüze hamburger</h5>
                <p className="card-text" data-languagePartIdentifier="ogien-tar">marhahúspogácsa,cheddar sajt,jégsaláta,paradicsom,uborka,jalapeno,chili szósz</p>
                <p className="card-text ">2.750 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'hambi' ? 'd-block' : 'd-none'} hambi`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="pod-burger">Dupla húsos hamburger</h5>
                <p className="card-text" data-languagePartIdentifier="pod-tar">dupla marhahúspogácsa,cheddar sajt,jégsaláta,paradicsom,uborka,lilahagyma,hambiszósz/ketchup/mustár/majonéz</p>
                <p className="card-text ">3.450 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'hambi' ? 'd-block' : 'd-none'} hambi`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="joker-burger">Joker burger</h5>
                <p className="card-text" data-languagePartIdentifier="joker-tar">dupla marhahúspogácsa,paradicsom,uborka,lilahagyma,jégsaláta,bacon,pirított hagyma,cheddar sajt,hambiszósz/ketchup/mustár/majonéz</p>
                <p className="card-text ">3.650 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          

 {/*Falafel*/}



 <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'fal' ? 'd-block' : 'd-none'} fal`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="fal-pita">Falafel pita</h5>
                <p className="card-text" data-languagePartIdentifier="fal-tar">5db falafel,paradicsom,uborka,lilahagyma,káposzta,tartármártás,sajt</p>
                <p className="card-text ">2.150 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'fal' ? 'd-block' : 'd-none'} fal`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="fal-tor">Falafel tortilla</h5>
                <p className="card-text ">2.150 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'fal' ? 'd-block' : 'd-none'} fal`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="fal-atl">Falafel tál</h5>
                <p className="card-text" data-languagePartIdentifier="fal-tal-tar">d8db falafel,paradicsom,uborka,lilahagyma,káposzta,hasábburgonya,tartármártás,sajt</p>
                <p className="card-text ">2.750 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
        


        {/*Francipirítós*/}

        <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'franc' ? 'd-block' : 'd-none'} franc`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="toast">Sonkás franciapirítós</h5>
                <p className="card-text" data-languagePartIdentifier="toast-tar">toast kenyér,sonka,ketchup/majonéz</p>
                <p className="card-text ">1.450 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'franc' ? 'd-block' : 'd-none'} franc`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="toast-2">Sajtos-sonkás franciapirítós</h5>
                <p className="card-text" data-languagePartIdentifier="toast-tar2">toast kenyér,sonka,ketchup/majonéz,sajt</p>
                <p className="card-text ">1.550 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'franc' ? 'd-block' : 'd-none'} franc`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="toast3">Szalámis franciapirítós</h5>
                <p className="card-text" data-languagePartIdentifier="sal-toast">toast kenyér,szalámi,ketchup/majonéz,sajt</p>
                <p className="card-text ">1.550 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
        
        <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'franc' ? 'd-block' : 'd-none'} franc`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="toast4">Zöldséges franciapirítós</h5>
                <p className="card-text" data-languagePartIdentifier="toast-tar4">toast kenyér,sonka,ketchup/majonéz,sajt ,uborka,paradicsom</p>
                <p className="card-text ">1.750 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'franc' ? 'd-block' : 'd-none'} franc`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="toast-kuc">Csirkehúsos franciapirítós</h5>
                <p className="card-text" data-languagePartIdentifier="toast-tar5">toast kenyér,ketchup/majonéz,sajt,gyroshús</p>
                <p className="card-text ">1.950 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
         
    {/*Köret/egyéb*/}

     <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'egyeb' ? 'd-block' : 'd-none'} egyeb`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="krumpli">Hasábburgonya</h5>
                <p className="card-text ">700 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'egyeb' ? 'd-block' : 'd-none'} egyeb`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="valami1">Édesburgonya</h5>
                <p className="card-text ">950 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'egyeb' ? 'd-block' : 'd-none'} egyeb`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="valami2">Csónakburgonya</h5>
                <p className="card-text ">950 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
        <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'egyeb' ? 'd-block' : 'd-none'} egyeb`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="ontet">Rántott hagymakarika</h5>
                <p className="card-text ">950 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
          <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'egyeb' ? 'd-block' : 'd-none'} egyeb`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="ontet1">Burger szósz,chili szósz,ketchup,mustár,majonéz</h5>
                <p className="card-text ">300 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
            </div>
            <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'egyeb' ? 'd-block' : 'd-none'} egyeb`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="ontet2">Fokhagymás öntet,kapros öntet,tartármártás,tzatziki öntet</h5>
                <p className="card-text ">300 Ft</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
            </div>
            <div className={`carousel-item ${selectedCategory === 'all' || selectedCategory === 'egyeb' ? 'd-block' : 'd-none'} egyeb`}>
            <div className="card">
              {/* <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="Card image cap" /> */}
              <div className="card-body">
                <h5 className="card-title" data-languagePartIdentifier="desszert">Desszert</h5>
                <p className="card-text " data-languagePartIdentifier="hamarosan">Hamarosan</p>
                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
              </div>
         </div>
         
         </div>

         </div>
        <button className="carousel-control-prev" type="button" onClick={handlePrevClick}>
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" onClick={handleNextClick}>
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
        </div>
     
      

    </>
  );
};

export default CarouselWithCards;
