
import './App.css';
import Header from './Header/Header';
import Heros from './Hero/Heros';
import AbaoutUs from './AbaoutUs/AbaoutUs';
import Footer from './Footer/Footer';
import Carousel from './Carousel/Carousel';
import React, { Component } from 'react';



export default class App extends Component{


  componentDidMount() {
    languagePreferencesSystem.reloadAllLanguageResources()
  }
  
  render(): React.ReactNode{

  return (
    <div className="App">
     <Header/>
    <Heros/>
     <AbaoutUs/>
     
   <Carousel/>
     
     <Footer/>
     
    </div>
  );
}

}

