import React from "react"
import "./Hero.css"



const Heros = () => {
  return (
    <>
      <section className='hero'>
        <div className='conatiner' style={{alignItems: "center", textAlign: "center"}}>
          <div className='row' style={{alignItems: "center", justifyContent: "center"}}>
          </div>
        </div>
      </section>
    </>
  )
}

export default Heros