import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import './footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Footer() {
  return (
    <MDBFooter className='footer' id='contact'>
      <MDBContainer className='p-4'>
        <MDBRow className='footer-row'>
          <MDBCol lg="3" md="6" className='mb-4 mb-md-0'>
            {/* <h5 className='text-uppercase'>Elérhetőségeink</h5> */}

            <iframe 
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2698.0880066093887!2d21.4014919!3d47.4492239!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474719041bff518f%3A0x4429cd68af37e54f!2sJoker%20Pizzeria%20%26%20Gyros%20Bar!5e0!3m2!1shu!2shu!4v1721311648647!5m2!1shu!2shu" 
            width="100%" 
            height="400px" 
            style={{border:0}}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade">
            </iframe>
          </MDBCol>

          <MDBCol lg="3" md="6" className='mb-4 mb-md-0'>
            <h5 className='text-uppercase' data-languagePartIdentifier="kapcs">Kapcsolat</h5>

            <ul className='list-unstyled mb-0'>
              <li>
                <p className='text-white'>
                  Hajdúszoboszló, Hőforrás u. 2 
                </p>
              </li>
              <li>
                <p className='text-white'>
                  06 30 483 6668
                </p>
              </li>
              <li>
                <img src='visa.png' />
                <img src='maestro.png' />
                <img src='mastercard.png' />
                <img src='c_kh_szep.png' />
                <img src='c_mkb_szep.png' />
                <img src='c_otp_szep.png' />
              </li>
              <li>
              </li>
            </ul>
          </MDBCol>
          

          <MDBCol lg="3" md="6" className='mb-4 mb-md-0'>
            <h5 className='text-uppercase mb-0' data-languagePartIdentifier="nyit">Nyitvatartás</h5>

            <ul className='list-unstyled'>
              <li>
                <p className='text-white' data-languagePartIdentifier="hetfo">
                  Hétfő: 0 - 24
                </p>
              </li>
              <li>
              <p className='text-white' data-languagePartIdentifier="kedd">
                  Kedd: 0 - 24
                </p>
              </li>
              <li>
              <p className='text-white' data-languagePartIdentifier="szerda">
                  Szerda: 0 - 24
                </p>
              </li>
              <li>
              <p className='text-white' data-languagePartIdentifier="csutortok">
                  Csütörtök: 0 - 24
                </p>
              </li>
              <li>
              <p className='text-white' data-languagePartIdentifier="pentek">
                  Péntek: 0 - 24
                </p>
              </li>
              <li>
              <p className='text-white' data-languagePartIdentifier="szombat">
                  Szombat: 0 - 24
                </p>
              </li>
              <li>
              <p className='text-white'  data-languagePartIdentifier="vasarnap">
                  Vasárnap: 0 - 24
                </p>
              </li>
            </ul>
          </MDBCol>
          <MDBCol lg="3" md="6" className='mb-4 mb-md-0'>
            <h5 className='text-uppercase' data-languagePartIdentifier="rendeles">Házhozszállítás</h5>

            <ul className='list-unstyled mb-0'>
              <li>
                <p className='text-white' data-languagePartIdentifier="felvetel" >
                 Hajdúszoboszló terülteén ingyenes!
                </p>
              </li>
              <li>
                <p className='text-white' data-languagePartIdentifier="minimum-1" >
                 Ebes,Nagyhegyes,Hajdúszovát területére a minimum rendelés: 6.000 Ft
                </p>
              </li>
              <li>
              <p className='text-white' data-languagePartIdentifier="minimum-2" >
                Nádudvar,Kaba,Balmazújváros minimum rendelés: 7.000 Ft
              </p>
              </li>
              <li>
              <p className='text-white'>
              <p data-languagePartIdentifier="felvetel">Rendelésfelvétel: </p> 
               <p data-languagePartIdentifier="vas-szomb" >Hétfő - Csütörtök és Vasárnap: 22:30-ig</p>
               <p data-languagePartIdentifier="szomb-vas">Péntek - Szombat: 23:30-ig</p>
              </p>
              </li>
              <li>
              <p className='text-white'>
              <p data-languagePartIdentifier="kiszallitas">Kiszállítás:</p> 
              <p data-languagePartIdentifier="kivas">Hétfő - Csütörtök és Vasárnap: 23:00-ig</p> 
              <p data-languagePartIdentifier="kiszob">Péntek - Szombat: 00:00-ig</p> 
              </p>
              </li>
              <li>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div className='text-center p-3' >
        <p>Joker Pizzéria & Gyros Bar Hajdúszoboszló | Just Ready Solution</p>
        {/* <a className='text-white' href='https://mdbootstrap.com/'>
          MDBootstrap.com
        </a> */}
      </div>
    </MDBFooter>
  );
}