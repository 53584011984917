import React, { useState } from "react";
import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import './Header.css'
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-scroll';



function Navbar() {
    const [nav, setNav] = useState(false)
    const handleNav = () => setNav(!nav)
  return (
    <div id='home' className={nav ? 'header header-bg' : 'header'}>
    <div className={nav ? 'logo dark' : 'logo'}>
        {/* <h2>Mazányi Judit</h2> */}
        {/* <img src="ic4.jpg" style={{height:"50px"}}/> */}
      </div>
      <ul className="header-menu">
      <Link to='main' smooth={true} duration={500} ><li className="header-li" data-languagePartIdentifier="fooldal">Főoldal</li></Link>
      <Link to='menu' smooth={true} duration={500} ><li className="header-li" data-languagePartIdentifier="menu">Menük</li></Link>
      <Link to='about' smooth={true} duration={500} ><li className="header-li" data-languagePartIdentifier="rolunk">Rólunk</li></Link>
      <Link to='contact' smooth={true} duration={500} ><li className="header-li" data-languagePartIdentifier="kapcsolat">Kapcsolat</li></Link> {/* 'Viwes' helyett 'Views' */}
      <li  >
                {/* @ts-ignore */}
                <button   switchlanguage='en' className="li-flag"  style={{  border: "black", whiteSpace: "1", letterSpacing:"1.5px", cursor: "pointer"}}>
                    {''}
                <img src='/united-kingdom (1).png' alt=''  style={{width: "20px", height:"20px"}} /> 
                <span style={{  fontSize:"13px",color: "white",marginLeft: "3px" }}>ENG</span>
               </button> 
               
                    </li>
                    <li >
                    {/*@ts-ignore*/}
                    <button switchlanguage='rom' className="li-flag" style={{  border: "black", letterSpacing:"1.5px", cursor: "pointer"}}>
                    <img src='/romania.png' alt=''  style={{width: "20px", height:"20px"}}  />
                    <span style={{  fontSize:"13px",color: "white",marginLeft: "3px" }}>ROM</span>
                    </button>
                   
                    </li>
                    <li > 
                    {/*@ts-ignore*/}
                    <button switchlanguage='hu' className="li-flag" style={{  border: "black", whiteSpace: "1", letterSpacing:"1.5px", cursor: "pointer"}}>
                    <img src='/hungary (1).png' alt='' style={{width: "20px", height:"20px"}}   />
                    <span style={{ fontSize:"13px",color: "white",marginLeft: "3px"}}>HUN</span>
                    </button>
                   
                    </li>
                    <li >
                    {/*@ts-ignore*/}
                    <button  switchlanguage='pol' className="li-flag" style={{  border: "black", letterSpacing:"1.5px", cursor: "pointer"}}>
                    <img src='/poland (1).png' alt='pol' style={{width: "20px", height:"20px"}}    />
                    <span style={{ fontSize:"13px",color: "white",marginLeft: "3px" }}>POL</span>
                    </button>
                    
                    </li>

      </ul>
     
      

      <div className="hamburger" onClick={handleNav}>
      {!nav ? (<HiOutlineMenuAlt4 className='icon' />) : (<AiOutlineClose  className='icon' />)}
      </div>

      <div className={nav ? 'mobile-menu active' : 'mobile-menu'}>
        <ul className="mobile-header">
      <Link to='main' smooth={true} duration={500}><li data-languagePartIdentifier="fooldal" >Főoldal</li></Link>
      <Link to='menu' smooth={true} duration={500}  ><li data-languagePartIdentifier="menu">Menük</li></Link>
      <Link to='about' smooth={true} duration={500} ><li data-languagePartIdentifier="rolunk">Rólunk</li></Link>
      <Link to='contact' smooth={true} duration={500}  ><li data-languagePartIdentifier="kapcsolat">Kapcsolat</li></Link> 
      <li>
                {/* @ts-ignore */}
                <button   switchlanguage='en' className="li-flag"  style={{  border: "black", whiteSpace: "1", letterSpacing:"1.5px", cursor: "pointer"}}>
                    {''}
                <img src='/united-kingdom (1).png' alt=''  style={{width: "20px", height:"20px"}} /> 
                <span style={{  fontSize:"13px",color: "white",marginLeft: "3px" }}>ENG</span>
               </button>
               
                    </li>
                    <li >
                    {/*@ts-ignore*/}
                    <button switchlanguage='rom' className="li-flag" style={{  border: "black", letterSpacing:"1.5px", cursor: "pointer"}}>
                    <img src='/romania.png' alt=''  style={{width: "20px", height:"20px"}}  />
                    <span style={{  fontSize:"13px",color: "white",marginLeft: "3px" }}>ROM</span>
                    </button>
                   
                    </li>
                    <li > 
                    {/*@ts-ignore*/}
                    <button switchlanguage='hu' className="li-flag" style={{  border: "black", whiteSpace: "1", letterSpacing:"1.5px", cursor: "pointer"}}>
                    <img src='/hungary (1).png' alt='' style={{width: "20px", height:"20px"}}   />
                    <span style={{ fontSize:"13px",color: "white",marginLeft: "3px"}}>HUN</span>
                    </button> 
                   
                    </li>
                    <li >
                    {/*@ts-ignore*/}
                    <button  switchlanguage='pol' className="li-flag" style={{  border: "black", letterSpacing:"1.5px", cursor: "pointer"}}>
                    <img src='/poland (1).png' alt='' style={{width: "20px", height:"20px"}}   />
                    <span style={{ fontSize:"13px",color: "white",marginLeft: "3px" }}>POL</span>
                    </button>
                   
                    </li>
        </ul>
        
      </div>
     
    </div>
  );
}

export default Navbar;