import React from 'react';
import './AbaoutUs.css'
import BackToTopButton from '../BacktToTopButton';

const AbaoutUs = () => {
  return (
    <>
    <section className='about'>
   <div className='main'>
    <img src='logo-harom.png' alt=''></img>
    <div className='about-text'>
      <p data-languagePartIdentifier="joker">Üdvözlünk a Joker Bisztróban, ahol a friss alapanyagokból készült ínycsiklandó pizzák, szaftos gyrosok, zamatos hot-dogok, ízletes hamburgerek és különleges boxok várnak rád.
         Élvezd a barátságos légkört és a kiváló ár-érték arányt, miközben új ízeket fedezel fel. Legyen szó egy gyors harapnivalóról vagy egy nyugodt vacsoráról, nálunk minden étkezés emlékezetes élmény lesz.
         Látogass el hozzánk, és hagyd, hogy az ízek varázsa elvarázsoljon!</p>
<a href='/aboutMe'>
            {/* <button className='buttonRight' style={{ borderRadius: "100px", borderColor: "rgba(0,0,0,0)", color:"#6E6D6E", letterSpacing:"2px", fontFamily:"Montserrat,Helvetica,Arial,Lucida,sans-serif"}}>
              Rólam
            </button> */}
          </a>
    </div>
   </div>
    </section>
    <BackToTopButton/>
    </>
  );
};

export default AbaoutUs;
